.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 12px;
  padding: 6px 0;
  box-shadow: inset 0px -1px 0px #EEEEEE;
  
  .label {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: #999999;
  }
  
  .value {
    font-weight: 700;
    font-size: 13px;
    line-height: 140%;
    color: #333333;
  }
}
