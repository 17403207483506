.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  background: #FFFFFF;
  box-shadow: inset 0px -1px 0px #EEEEEE;
  border-radius: 3px;
  text-align: center;
  
  .month {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
  }
  
  .day {
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
  }
  
  .weekday {
    font-weight: 400;
    font-size: 11px;
    line-height: 140%;
  }
}
