.root {
  display: flex;
  align-items: center;
  padding-top: 4px;
  
  :global(.adm-dropdown-item) {
    justify-content: flex-start;
  }
  
  :global(.adm-dropdown-item-title) {
    padding: 0;
    font-size: 17px;
    line-height: 1.5;
  }
  
  &.readonly {
    pointer-events: none;
    opacity: 0.8;
  }
}
