.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  z-index: 1000;
  background-color: var(--header);
  color: #fff;

  .action {
    font-size: 24px;
    cursor: pointer;
  }
}
