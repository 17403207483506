.root {
  padding-left: 12px;
  background: #FFFFFF;
  
  .wrapper {
    box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1) inset; 
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px 10px 0px;
    flex: none;
    order: 0;
    flex-grow: 1;
  }
  
  .icon {
    color: #999999;
    font-size: 20px;
    margin-inline-end: 10px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  
  .righticon {
    color: #999999;
    font-size: 20px;
    margin-inline-start: auto;
  }
  
  .title {
    font-weight: 700;
    font-size: 13px;
    line-height: 140%;
    color: #333333;
  }
  .highlight .title {
    color: #1677FF;
  }
  
  .description {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: #999999;
  }
}
