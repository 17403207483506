:root {
  // material colors https://material.io/resources/color
  --blue-grey-50: #ECEFF1;
  --blue-grey-100: #CFD8DC;
  --blue-grey-200: #B0BEC5;
  --blue-grey-300: #90A4AE;
  --blue-grey-400: #78909C;
  --blue-grey-500: #607D8B;
  --blue-grey-600: #546E7A;
  --blue-grey-700: #455A64;
  --blue-grey-800: #37474F;
  --blue-grey-900: #263238;

  --c-scroll-bg: #222;
  --c-scroll: #444;

  --header: #08979C;
}
