.root {
  background: #FFFFFF;
  
  .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 12px;
    
    .name {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
    }
    
    .description {
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      color: #999999;
    }
  }
}
