.root {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 1000;
  background: #fff;
  box-shadow: inset 0 1px 0 #EEEEEE;
  padding-top: 2px;
  
  .item {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 50px;
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
  }
}
