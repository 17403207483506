[dir="rtl"] .root {
  .title .ticketTime {
    position: absolute;
    transform: translateX(calc(30px + 100%));
  }
}

.root {
  overflow: hidden;

  :not(:first-child) :global(.adm-step-indicator) {
    margin-top: -8px;
  }

  :not(:first-child) :global(.adm-step-icon-container) {
    margin-top: 8px;
  }

  .title .ticketTime {
    position: absolute;
    transform: translateX(calc(-30px - 100%));
  }

  .title .ticketTitle {
    color: #1677FF;
  }

  .cancelled {
    .title .ticketTitle {
      text-decoration: line-through;
    }
  }

  .dndStep {
    margin-bottom: 8px;
    padding: 4px 0;
  }

  .past {
    .title .ticketTime {
      color: #999999;
    }

    --icon-color: #CCCCCC;
    --line-to-next-color: #CCCCCC;
  }

  .current {
    .title .ticketTime {
      color: #FF8F1F;
    }

    --icon-color: #FF8F1F;
    --line-to-next-color: #FF8F1F;
  }

  .future {
    .title .ticketTime {
      color: #262626;
    }

    --icon-color: #1677FF;
    --line-to-next-color: #1677FF;
  }
}

.rootModal {
  .modalTitle {
    margin-bottom: 24px;
  }

  .rootSteps {
    :global(.adm-step-indicator) {
      display: none;
    }

    :global(.adm-step-icon-container) {
      margin-top: 8px;
    }

    .step {
      margin-right: 0;
      margin-left: 35px;
      --icon-color: #FF8F1F;
      --line-to-next-color: #FF8F1F;

      .title .ticketTime {
        position: absolute;
        transform: translateX(calc(-15px - 100%));
      }

      .title .ticketTitle {
        color: #1677FF;
      }
    }
  }
}


[dir="rtl"] .rootModal .rootSteps .step {
  margin-left: 0;
  margin-right: 35px;

  .title .ticketTime {
    transform: translateX(calc(15px + 100%));
  }
}

