.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 12px;
  width: 100%;
  gap: 12px;
  background: #FFF7E6;
  border-radius: 10px 10px 0px 0px;
  
  &.cancelled {
    background: #F0F0F0;
    height: 39px;
    font-weight: 700;
    font-size: 11px;
    justify-content: center;
    color: #333333;
  }
}
