[dir="rtl"] {
  .adm-calendar-arrow-button,
  .adm-calendar-arrow-button-year,
  .adm-calendar-arrow-button-right,
  .adm-calendar-arrow-button-right-year {
      transform: scaleX(-1);
  }
}

* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  &::-webkit-scrollbar-track {
    background-color: var(--c-scroll-bg);
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--c-scroll);
    border: 0;
  }
}

html {
}

body {
}

.none {
  opacity: 0;
  pointer-events: none;
}

.lds-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 51px;
      height: 51px;
      margin: 6px;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border: 6px solid #001529;
      border-color: #001529 transparent transparent transparent;
    }

    div:nth-child(1) {
      animation-delay: -0.45s;
    }

    div:nth-child(2) {
      animation-delay: -0.3s;
    }

    div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

a {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: #fff;
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-text-fill-color: #000;
}

.clickable { cursor: pointer }
.not-clickable { pointer-events: none; }
.bold { font-weight: 700; }
.rigid { flex-shrink: 0; }
.fill { flex: 1; }

.text-xs { font-size: 11px; }
.text-sm { font-size: 13px; }
.text-md { font-size: 15px; }
.text-lg { font-size: 18px; }
//.text-xl { font-size: 24px; }

.color-black { color: #333333; }
.color-gray { color: #999999; }

.bg-white { background: #ffffff; }
.border-b { box-shadow: inset 0px -1px 0px #EEEEEE; }
.border { border: 1px solid #EEEEEE; }
.rounded { border-radius: 9999px; }

.ma1 { margin: 4px; }
.ma2 { margin: 8px; }
.ma3 { margin: 12px; }
.ma4 { margin: 16px; }
.ma5 { margin: 20px; }
.ma6 { margin: 24px; }
.ma7 { margin: 28px; }
.ma8 { margin: 32px; }
.ma9 { margin: 36px; }
.ma10 { margin: 40px; }
.mt1 { margin-top: 4px; }
.mt2 { margin-top: 8px; }
.mt3 { margin-top: 12px; }
.mt4 { margin-top: 16px; }
.mt5 { margin-top: 20px; }
.mt6 { margin-top: 24px; }
.mt7 { margin-top: 28px; }
.mt8 { margin-top: 32px; }
.mt9 { margin-top: 36px; }
.mt10 { margin-top: 40px; }
.mr1 { margin-inline-end: 4px; }
.mr2 { margin-inline-end: 8px; }
.mr3 { margin-inline-end: 12px; }
.mr4 { margin-inline-end: 16px; }
.mr5 { margin-inline-end: 20px; }
.mr6 { margin-inline-end: 24px; }
.mr7 { margin-inline-end: 28px; }
.mr8 { margin-inline-end: 32px; }
.mr9 { margin-inline-end: 36px; }
.mr10 { margin-inline-end: 40px; }
.mb1 { margin-bottom: 4px; }
.mb2 { margin-bottom: 8px; }
.mb3 { margin-bottom: 12px; }
.mb4 { margin-bottom: 16px; }
.mb5 { margin-bottom: 20px; }
.mb6 { margin-bottom: 24px; }
.mb7 { margin-bottom: 28px; }
.mb8 { margin-bottom: 32px; }
.mb9 { margin-bottom: 36px; }
.mb10 { margin-bottom: 40px; }
.ml1 { margin-inline-start: 4px; }
.ml2 { margin-inline-start: 8px; }
.ml3 { margin-inline-start: 12px; }
.ml4 { margin-inline-start: 16px; }
.ml5 { margin-inline-start: 20px; }
.ml6 { margin-inline-start: 24px; }
.ml7 { margin-inline-start: 28px; }
.ml8 { margin-inline-start: 32px; }
.ml9 { margin-inline-start: 36px; }
.ml10 { margin-inline-start: 40px; }

.pa1 { padding: 4px; }
.pa2 { padding: 8px; }
.pa3 { padding: 12px; }
.pa4 { padding: 16px; }
.pa5 { padding: 20px; }
.pa6 { padding: 24px; }
.pa7 { padding: 28px; }
.pa8 { padding: 32px; }
.pa9 { padding: 36px; }
.pa10 { padding: 40px; }
.pt1 { padding-top: 4px; }
.pt2 { padding-top: 8px; }
.pt3 { padding-top: 12px; }
.pt4 { padding-top: 16px; }
.pt5 { padding-top: 20px; }
.pt6 { padding-top: 24px; }
.pt7 { padding-top: 28px; }
.pt8 { padding-top: 32px; }
.pt9 { padding-top: 36px; }
.pt10 { padding-top: 40px; }
.pr1 { padding-inline-end: 4px; }
.pr2 { padding-inline-end: 8px; }
.pr3 { padding-inline-end: 12px; }
.pr4 { padding-inline-end: 16px; }
.pr5 { padding-inline-end: 20px; }
.pr6 { padding-inline-end: 24px; }
.pr7 { padding-inline-end: 28px; }
.pr8 { padding-inline-end: 32px; }
.pr9 { padding-inline-end: 36px; }
.pr10 { padding-inline-end: 40px; }
.pb1 { padding-bottom: 4px; }
.pb2 { padding-bottom: 8px; }
.pb3 { padding-bottom: 12px; }
.pb4 { padding-bottom: 16px; }
.pb5 { padding-bottom: 20px; }
.pb6 { padding-bottom: 24px; }
.pb7 { padding-bottom: 28px; }
.pb8 { padding-bottom: 32px; }
.pb9 { padding-bottom: 36px; }
.pb10 { padding-bottom: 40px; }
.pl1 { padding-inline-start: 4px; }
.pl2 { padding-inline-start: 8px; }
.pl3 { padding-inline-start: 12px; }
.pl4 { padding-inline-start: 16px; }
.pl5 { padding-inline-start: 20px; }
.pl6 { padding-inline-start: 24px; }
.pl7 { padding-inline-start: 28px; }
.pl8 { padding-inline-start: 32px; }
.pl9 { padding-inline-start: 36px; }
.pl10 { padding-inline-start: 40px; }

.flex {
  display: flex;
  
  &.col {
    flex-direction: column;
  }

  &.baseline {
    align-items: baseline;
  }

  &.center {
    align-items: center;
    justify-content: center;
  }

  &.center-v {
    align-items: center;
  }

  &.center-h {
    justify-content: center;
  }

  &.align-r {
    justify-content: flex-end;
  }

  &.between {
    justify-content: space-between;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}

.ant-tabs .ant-tabs-nav {
  margin: 0 0 2px;
}

.adm-input {
  height: 29px;
}
[dir="rtl"]{
.InfoItem_value__PgEew > a, .ListItem_description__Sk2Ie {
    direction: ltr;
    display: block;
    text-align: right;
  }
}