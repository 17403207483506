[dir="rtl"] .root {
  [id="LeftOutline-LeftOutline"], [id="RightOutline-RightOutline"] {
    transform: scaleX(-1)translateX(-100%);
  }
}

.root {
  min-height: 100vh;
  background: #fafafa;
}
