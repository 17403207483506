.droppable {
  .draggable {
    border: 2px outset ButtonBorder;
    font-family: var(--adm-font-family);
    border-radius: 8px;
    width: 100%;
    opacity: 1;
    touch-action: none;
  }
}

.droppable.isDraggableSome {
  opacity: 0.5;

  .draggable,
  .draggable:hover,
  .draggable:active,
  .draggable:hover:active,
  .draggable:focus {
    background-color: ButtonFace;
  }
}

.droppable.isDraggableSome.isOver {
  opacity: 1;
}

.droppable.isDraggableSome.isDraggableActiveCurrent {
  opacity: 0.5;

  .draggable,
  .draggable:hover,
  .draggable:active,
  .draggable:hover:active,
  .draggable:focus {
    background-color: transparent;
  }
}