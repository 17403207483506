.root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  padding: 4px 12px;
  gap: 8px;
  height: 29px;
  border-radius: 48px;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #FFFFFF;
  width: fit-content;
  &.fill {
    width: 100%;
  }
  
  &.solid {
    &.primary {
      background: #1677FF;
    }
    &.success {
      background: #00B578;
    }
    &.warning {
      background: #FF8F1F;
    }
    &.danger {
      background: #FF3141;
    }
  }
  
  &.outline {  
    &.primary {
      border: 1px solid #1677FF;
      color: #1677FF;
    }
    &.success {
      border: 1px solid #00B578;
      color: #00B578;
    }
    &.warning {
      border: 1px solid #FF8F1F;
      color: #FF8F1F;
    }
    &.danger {
      border: 1px solid #FF3141;
      color: #FF3141;
    }
  }
}
